import React, { useEffect, useState } from "react";
import { fetchProducts } from "../action/especificos/FutbolistaActions";
import "../css/Cancha.css";
import "../css/442.css";
import "../css/343.css";
import "../css/352.css";
import "../css/433.css";
import "../css/532.css";
import "../css/541.css";
import "../css/4231.css";
import "../css/4312.css";
import "../css/4321.css";
import "../css/4141.css";
import "../css/Suplentes.css";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {Animated} from "react-animated-css";
import imgvacia from '../assets/imagenes/fans-colegiales/generales/boy1-sm.png'

function Formacion1() {
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const encuentros = useSelector((state) => state.products);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (load) {
      const loadProducts = () => dispatch(fetchProducts());
      loadProducts();
      setLoad(false);
    }
  }, [encuentros, dispatch, load]);

// console.log("userinfo", userInfo.id_cliente);
  return (
    <React.Fragment>
      {!encuentros.items ? (
        <Slider />
      ) : (
        <div>
            <div style={{ textAlign: "center", marginTop: "10px" }} >
              {/* <h1 className="h1">Titulares</h1> */}
            </div>
            <div
              id="cancha"
              className={encuentros.items.encuentro.clase_formacion}
            >
              <div id="imagen_cancha"></div>
              
              {encuentros.items.formacion.map((formacion) => {
                let opacarImg ="";
                let opacarTitle="";
                let opacarCursor="";
                let habilitar = (event) => true;
                const calificados= encuentros.items.calificados;
                
                for (const key in calificados) {
                  
                    const id_futbolista = calificados[key]["id_futbolista"];
                    if(id_futbolista === formacion.id_futbolista){
                      opacarImg ="opacarImg";
                      opacarTitle ="opacarTitle";
                      habilitar= (event) => event.preventDefault(true);
                      opacarCursor="opacarCursor";
                      
                      console.log("entro")
                    }
                 
                }
                return (
                  <div
                    key={formacion.id}
                    className={`jugador titular ${formacion.id}`}
                    id={formacion.id}
                  >
                   <br/><br/><br/>
                  
                   <Animated animationIn="bounceIn" animationInDelay={1500} animationOut="bounce" animationInDuration={2000} animationOutDuration={2000} isVisible={true}>
                    <Link
                      className={`link-name ${opacarImg} ${opacarCursor}`}
                      onClick={habilitar}
                      to={`/futbolista/${formacion.id_futbolista}/${formacion.nombre}/${formacion.imagen_futbolista}`}
                    >
                      {formacion.imagen_futbolista === "" ? (
                        <img className="camiseta" src={imgvacia} alt="Sólo fanáticos" />
                      ) : (
                       
                        <img
                        alt="Sólo fanáticos"
                          className="camiseta"
                          src={`${config_server.linkImageFutbolista}${formacion.imagen_futbolista}`}
                        />
                        
                      )}
                       <p className="nro-camiseta">{formacion.nro}</p>
                       <br/>
                       
                      
                     
                      
                      <p className={ `nombreju ${opacarTitle}`}>
                      {formacion.alias_futbolista}
                    </p>
                     
                     
                    </Link>
                    </Animated>
                    
                  </div>
                );
              })}
            </div>
          </div>
        
      )}
    </React.Fragment>
  );
}

export default Formacion1;
